// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-article-js": () => import("./../src/layouts/article.js" /* webpackChunkName: "component---src-layouts-article-js" */),
  "component---src-layouts-breed-breed-js": () => import("./../src/layouts/breed/breed.js" /* webpackChunkName: "component---src-layouts-breed-breed-js" */),
  "component---src-layouts-podcast-podcast-js": () => import("./../src/layouts/podcast/podcast.js" /* webpackChunkName: "component---src-layouts-podcast-podcast-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-czytam-js": () => import("./../src/pages/czytam.js" /* webpackChunkName: "component---src-pages-czytam-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-mnie-js": () => import("./../src/pages/o-mnie.js" /* webpackChunkName: "component---src-pages-o-mnie-js" */),
  "component---src-pages-podstawowe-zwroty-js": () => import("./../src/pages/podstawowe-zwroty.js" /* webpackChunkName: "component---src-pages-podstawowe-zwroty-js" */),
  "component---src-pages-slucham-js": () => import("./../src/pages/slucham.js" /* webpackChunkName: "component---src-pages-slucham-js" */)
}

